import { useEffect, useState } from "react";

export function Collision({ color, size }: any) {
    return (
        <mesh scale={size}>
            <boxGeometry />
            <meshStandardMaterial color={color} />
        </mesh>
    );
}
