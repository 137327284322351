import { gql } from '@apollo/client';

export const PROJECTS_DATA = gql`
query {
    projects {
            edges {
            node {
                title
                id
                  description:content
                image:featuredImage {
                    node {
                      id
                      sourceUrl
                    }
                  }
                  
                projects_meta {
                duration
                price
                link
                imgformobile {
                	id
                	sourceUrl
                }
                excerpt
                }
            }
        }
    }
}
`;


export const TECH_LOGOS_DATA = gql`
query {
    techLogos (first:100) {
        edges {
          node {
            title
            id
            excerpt
            tech_logos_meta {
              icontitle
              iconcontent
              iconimg {
                id
                sourceUrl
              }
            }
            image: featuredImage {
              node {
                id
                sourceUrl
              }
            }
          }
        }
      }
}
`;


export const RESUME_DATA = gql`

query {
  resumeSteps {
      edges {
        node {
          title
          resume_steps {
            resumecontent
            resumeyearsrange
          }
          image: featuredImage {
            node {
              id
              sourceUrl
            }
          }
        }
      }
    }
}
`;


export const MAIN_PAGE_DATA = gql`  
query {
  page (id: "cG9zdDo2") {
    mainpage {
      info
      story
    }
    title
    content
    image: featuredImage {
      node {
        id
        sourceUrl
      }
    }
    }    
}
`;