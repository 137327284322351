import { useEffect, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";
import { PresentationControls, Stage } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Collision } from "./Collision";

export function Ship() {
    const [position, setPosition] = useState(0);

    const colors = ["red", "blue", "green", "yellow", "pink"];
    // random of colors
    const [color, setColor] = useState("red");
    const [size, setSize] = useState(0.5);

    const gltf = useLoader(GLTFLoader, "./mainShip/scene.gltf");
    let count = 0;
    let shoot = 0;

    useFrame(({ scene }) => {
        scene.children[2]?.position.set(shoot, 0, 0);
        count += 0.01;
        shoot += 1;
        gltf.scene.children[0].rotation.x += (Math.sin(count) * 0.005) / 10;
        gltf.scene.children[0].position.x += Math.sin(count) * 0.005;
    }, []);

    const fireShip = () => {
        setPosition(1);
    };

    useEffect(() => {
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        setColor(randomColor);
        setSize(Math.random() * 2 + 0.1);
    }, [position]);

    useEffect(() => {
        gltf.scene.traverse((object) => {
            if (object instanceof Mesh) {
                object.castShadow = false;
                object.receiveShadow = false;
                object.material.envMapIntensity = 0.1;
                object.material.metalness = 0.6;
                object.material.roughness = 0.4;
            }
        });
    }, []);

    useEffect(() => {
        setPosition(0);
    }, [position]);

    return (
        <>
            <PresentationControls enabled={false} cursor={false}>
                <Stage shadows={false}>
                    <primitive onClick={fireShip} object={gltf.scene} />
                </Stage>
            </PresentationControls>
            <Collision color={color} size={size} />
        </>
    );
}
