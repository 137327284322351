import React, { useEffect, useState } from 'react';

import { useProxy } from "valtio/utils";
import { state } from "../../store";

import style from './Story.module.scss';
import meImg1 from '../../assets/1.png';
import meImg2 from '../../assets/2.png';
import meImg3 from '../../assets/3.png';
import meImg4 from '../../assets/11.png';

import { motion } from 'framer-motion';

function Story({ storyContent, storyHTML }: any) {

    const snap = useProxy(state);
    const [profileImgIndex, setProfileImgIndex] = useState<number>(0);

    const elEventScroll = (element: any) => {
        const maxScroll = element.scrollHeight - element.clientHeight;
        const maxScrollFragment = maxScroll / 3;
        const intScroll: number = +(element.scrollTop / maxScrollFragment).toFixed(0);
        setProfileImgIndex(intScroll);

    }

    useEffect(() => {
        const el = document.querySelector(`.${style.storyText}`);
        el && el.addEventListener('scroll', () => {
            elEventScroll(el)
        });

        return () => {
            el && el.removeEventListener('scroll', () => {
                elEventScroll(el)
            });
        }

    }, []);

    return (<>
        {!snap.isMobile ? <section className={style.storyContainer}>
            <div className={style.storyText}>
                <h2 className={style.mainTitle}>My Story</h2>
                <div>{storyContent}</div>
            </div>
            {profileImgIndex === 0 && <motion.img initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0 }} className={style.meImg} src={meImg1} alt="me" />}
            {profileImgIndex === 1 && <motion.img initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0 }} className={style.meImg} src={meImg2} alt="me" />}
            {profileImgIndex === 2 && <motion.img initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0 }} className={style.meImg} src={meImg3} alt="me" />}
            {profileImgIndex === 3 && <motion.img initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0 }} className={style.meImg} src={meImg4} alt="me" />}
        </section> : <div className={style.storyContainer}>
            <h2 className={style.mainTitle}>My Story</h2>
            <div dangerouslySetInnerHTML={{ __html: storyHTML }}></div>
        </div>}
    </>);
}

export default Story;