import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import ProjectsGallery from "./components/ProjectsGallery/ProjectsGallery";
import TechLogos from "./components/TechLogos/TechLogos";
import Footer from "./components/Footer/Footer";
import Story from "./components/Story/Story";
import Resume from "./components/Resume/Resume";
import { useQuery } from "@apollo/client";
import style from "./App.module.scss";
import {
    MAIN_PAGE_DATA,
    PROJECTS_DATA,
    RESUME_DATA,
    TECH_LOGOS_DATA,
} from "./assets/queries";
import ComputersCanvas from "./components/Canvas/Computers";
import { motion } from "framer-motion";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";
import { Loader } from "@react-three/drei";
import ScrollNotation from "./components/ScrollNotation";

gsap.registerPlugin(ScrollTrigger);

function App() {
    const { error, loading, data: projectsData } = useQuery(PROJECTS_DATA);
    const {
        error: errorTechLogos,
        loading: loadingTechLogos,
        data: techLogosData,
    } = useQuery(TECH_LOGOS_DATA);
    const {
        error: errorResume,
        loading: loadingResume,
        data: ResumeData,
    } = useQuery(RESUME_DATA);
    const {
        error: errorMain,
        loading: loadingMain,
        data: dataMain,
    } = useQuery(MAIN_PAGE_DATA);

    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        });
    }, []);

    if (loading || loadingTechLogos || loadingResume || loadingMain)
        return <Loader />;
    if (error || errorTechLogos || errorResume || errorMain)
        return <p>Error :(error)</p>;

    return (
        <div className="App">
            <BrowserRouter>
                <div style={{ display: "flex" }}>
                    <div
                        className={
                            sticky
                                ? style.positionedHeaderFixed
                                : style.positionedHeader
                        }
                    >
                        <Header />
                    </div>
                    <motion.div
                        className={style.container}
                        initial={{ opacity: 0, y: 300 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 1 }}
                        viewport={{ once: true }}
                    >
                        <Hero data={dataMain} />
                    </motion.div>
                    <div
                        className={style.starsBackgorund}
                        style={{ height: "96vh", flex: 1 }}
                    >
                        <ComputersCanvas />
                        <div className={style.galaxy}>
                            <div className={style.starsSmall}></div>
                            <div className={style.starsMedium}></div>
                            <div className={style.starsLarge}></div>
                        </div>
                        <ScrollNotation />
                    </div>
                </div>
                <ProjectsGallery projects={projectsData.projects.edges} />
                <TechLogos techLogosData={techLogosData.techLogos.edges} />
                <Story
                    storyContent={dataMain.page.mainpage.story}
                    storyHTML={dataMain.page.content}
                />
                <Resume resumeData={ResumeData.resumeSteps.edges} />
            </BrowserRouter>
            <Footer />
        </div>
    );
}

export default App;
