import React from 'react';
import style from './Footer.module.scss';
import { motion } from 'framer-motion';

function Footer() {
    const date = new Date().getFullYear();
    return (<motion.div style={{ position: 'relative' }} initial={{ opacity: 0, y: -300, x: 0 }} whileInView={{ opacity: 1, y: 0, x: 0 }} viewport={{ once: true }}>
        <footer className={style.footer}>
            <h2 className={style.title}>The End.</h2>
            <div className={style.contactLinks}>
                <h4>Contacts 🤙</h4>
                <ul>
                    <li><a href='https://github.com/UwebDevCom' target='blank'>Git</a></li>
                    <li>
                        <a href='https://www.linkedin.com/in/yair-viner-3a9553149/' target='blank'>Linkedin</a>
                    </li>
                    <li>
                        <a href='https://Codcodile.co.il/' target='blank'>Codcodile</a>
                    </li>
                    <li>
                        <a href="mailto:hello@vvin.co.il">Email</a>
                    </li>
                </ul>
            </div>
            <div className={style.title}>
                <span className={style.reserved}> VVIN © {date} . All rights reserved.</span>
            </div>
        </footer>
    </motion.div>);
}

export default Footer;