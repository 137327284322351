import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import style from './Header.module.scss';

import { logo } from '../../assets';

const Header = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);

  return (
    <header>
      <nav className={style.container}>
        <Link to="/" className={style.linkContainer}
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}>
          <img src={logo} alt='logo' className={style.logo} />
          <p className={style.logoText}>
            <span className={style.name}>Yair Vin</span>
            <span>FrontEnd Developer</span></p>
        </Link>
      </nav>
    </header>
  )
}

export default Header;