import React, { useEffect, useState } from 'react';
import style from './TechLogos.module.scss';
import { ITechLogo } from '../../models';
import { motion } from 'framer-motion';


function TechLogos({ techLogosData }: any) {

    const [activeElement, setActiveElement] = useState<number>(8);
    useEffect(() => {

        const container: any = document.querySelector(`.${style.sectionContainer}`);
        const allLogos = document.querySelectorAll(`.${style.logoContainer}`);
        const radius = 520;
        const centerX = container.offsetWidth / 2;
        const centerY = container.offsetHeight / 2;

        allLogos.forEach((logo: any, i: number) => {
            const angle = -(i * 360) / allLogos.length + 360 / allLogos.length * activeElement - 90;
            const angleRadians = angle * (Math.PI / 180);

            const x = -35 + centerX + radius * Math.cos(angleRadians);
            const y = radius - 40 + centerY + radius * Math.sin(angleRadians);

            const prefix = 0.06;
            let scale = 1 - i * prefix;
            let offsetX = x;
            let offsetY = y;
            const middle = (allLogos.length - 1) / 2;
            if (i === middle) {

            } else if (i > middle) {
                scale = 1 - (i - middle) * prefix;
                offsetX = x - (i - middle) * 40;
            } else if (i < middle) {
                scale = 1 - (middle - i) * prefix;
                offsetX = x + (middle - i) * 40;
            }
            offsetX = offsetX - radius / 2 - 40;
            offsetY = offsetY + radius - 40;
            logo.style.left = `${x}px`;
            logo.style.top = `${y}px`;

            // logo.style.transform = `
            // scale(${scale})`;
        });

    }, [techLogosData, activeElement]);


    const handleTooltip = (index: number) => {
        setActiveElement(index);
    }

    return (<section className={style.sectionContainer}>
        {techLogosData.length && techLogosData.map(({ node: techLogo }: ITechLogo, i: number) =>
            <div key={techLogo.id}>
                <button onClick={() => handleTooltip(i)} className={`${activeElement === i
                    ? style.logoContainer + ' ' + style.logoContainerActive : style.logoContainer}`}>
                    <img src={techLogo.tech_logos_meta.iconimg.sourceUrl} alt={techLogo.title} />
                    <span>{techLogo.title}</span>
                </button>
                {activeElement === i
                    ? <motion.div initial={{ opacity: 0, y: -100, x: '-50%' }} whileInView={{ opacity: 1, y: 0, x: '-50%' }} className={style.tooltipTechLogo}>
                        <span>{techLogo.tech_logos_meta.iconcontent}</span>
                    </motion.div>
                    : null}
            </div>
        )}
        <div className={style.strokeContainer}></div>
        <div className={style.galaxy}>
            <div className={style.starsSmall}></div>
            <div className={style.starsMedium}></div>
            <div className={style.starsLarge}></div>
            <div className={style.galaxyShadow}></div>
        </div>
    </section>);
}

export default TechLogos;