import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import Loader from "../Loader";
import { Ship } from "./Ship";
import { OrbitControls } from "@react-three/drei";

const ComputersCanvas = () => {
    return (
        <Canvas
            style={{ height: 1000, background: "#e3e3e300" }}
            gl={{
                antialias: true,
                alpha: true,
            }}
            camera={{ position: [11, 10, 3] }}
        >
            <Suspense fallback={<Loader />}>
                <OrbitControls enableZoom={false} />
                {/* <axesHelper args={[100]} /> */}
                <pointLight position={[10, 20, 10]} intensity={1} />
                <Ship />
            </Suspense>
        </Canvas>
    );
};

export default ComputersCanvas;
