import React, { useEffect } from 'react';
import style from './Resume.module.scss';
import gStyle from '../../style.module.scss';
import styleStars from '../TechLogos/TechLogos.module.scss';

import platformImg from '../../assets/img/platform.png';
import { delay, motion } from 'framer-motion';

import spriteRunLeft from '../../assets/img/spriteRunLeft.png';
import spriteRunRight from '../../assets/img/spriteRunRight.png';
import spriteStandRight from '../../assets/img/spriteStandRight.png';
import spriteStandLeft from '../../assets/img/spriteStandLeft.png';

import { useProxy } from "valtio/utils";
import { state } from "../../store";

import arrow_keys from '../../assets/img/key_arrows.png';
import { is } from '@react-three/fiber/dist/declarations/src/core/utils';


let CARDS: HTMLDivElement[];

type canvasPosition = { x: number; y: number; };

function canvasDraw(canvas: HTMLCanvasElement, parentContainer: HTMLDivElement, resumeData: any | null, position?: canvasPosition | null, isMobile?: boolean, cardsInfo?: HTMLDivElement[] | null) {
    const context: CanvasRenderingContext2D = canvas?.getContext('2d') as CanvasRenderingContext2D;
    canvas.width = parentContainer.clientWidth;
    canvas.height = parentContainer.clientHeight;

    if (isMobile) {
        canvas.width = parentContainer.clientWidth;
        canvas.height = 500;
    }
    const gravity = 0.5;
    let scrollOffset = 0;
    const keys = {
        right: {
            pressed: false
        },
        left: {
            pressed: false
        },
        up: {
            pressed: false
        }
    }

    class Player {
        position: canvasPosition;
        width: number;
        height: number;
        velocity: { x: number; y: number; };
        image: HTMLImageElement;
        frames: number;
        sprites: any;
        currentSprite: HTMLImageElement;
        currentCropWidth: number;
        constructor() {
            this.position = position || { x: 500, y: 100 };
            this.width = 66;
            this.height = 150;
            this.velocity = {
                x: 0,
                y: 0
            }
            this.image = createImage(spriteStandRight);
            this.frames = 0;
            this.sprites = {
                stand: {
                    right: createImage(spriteStandRight),
                    left: createImage(spriteStandLeft),
                    cropWidth: 177,
                    width: 66
                },
                run: {
                    right: createImage(spriteRunRight),
                    left: createImage(spriteRunLeft),
                    cropWidth: 341,
                    width: 127.875
                }
            }

            this.currentSprite = this.sprites.stand.right;
            this.currentCropWidth = 177;
        }

        draw() {
            // context.fillStyle = 'red';
            // context.fillRect(this.position.x, this.position.y, this.width, this.height);
            context.shadowColor = '#000';
            context.shadowBlur = 0;
            context.drawImage(
                this.currentSprite,
                this.currentCropWidth * this.frames,
                0,
                this.currentCropWidth,
                400,
                this.position.x,
                this.position.y,
                this.width,
                this.height);

        }
        update() {
            this.frames++;
            if (this.frames > 59 && (this.currentSprite === this.sprites.stand.right || this.currentSprite === this.sprites.stand.left)) this.frames = 0
            else if (this.frames > 29 && (this.currentSprite === this.sprites.run.right || this.currentSprite === this.sprites.run.left)) this.frames = 0
            this.draw();
            this.position.y += this.velocity.y;
            this.position.x += this.velocity.x;

            if (this.position.y + this.height + this.velocity.y <= canvas.height) {
                this.velocity.y += gravity;
            } else {
                this.velocity.y = gravity;
            }
        }
    }

    let player = new Player();

    function createImage(imageSrc: any) {
        const image = new Image();
        image.src = imageSrc;
        return image
    }

    class Platform {
        image: HTMLImageElement;
        position: { x: number; y: number; };
        width: number;
        height: number;
        id: number;
        textBlock: string;

        constructor({ x, y, image, id, textBlock }: any) {
            this.position = {
                x,
                y
            }
            this.id = id;
            this.image = image;
            this.width = 300;

            if (isMobile) {
                this.width = 200;
            }

            this.height = 20;
            this.textBlock = textBlock;
        }
        draw() {
            context.font = "16px Lobster";
            context.fillStyle = "white";
            context.textAlign = "center";

            context.fillText(this.textBlock, this.position.x + this.width / 2, this.position.y + 30);
            context.fillStyle = "#5e72eb";
            context.shadowColor = '#5e72eb';
            context.shadowBlur = 10;
            context.shadowOffsetX = 0;
            context.shadowOffsetY = 0;
            context.fillRect(this.position.x, this.position.y, this.width, 5);


        }
    }


    let platformImgIns = createImage(platformImg);


    let platforms: any[];

    if (isMobile) {

        platforms = resumeData.map((resume: any, index: number) => {
            return new Platform({ textBlock: resume.node.resume_steps.resumeyearsrange, x: index % 2 ? 100 : 10, y: 200 + 200 * index, image: platformImgIns, id: index + 1 });
        })

    } else {
        platforms = resumeData.map((resume: any, index: number) => {
            return new Platform({ textBlock: resume.node.resume_steps.resumeyearsrange, x: 400 + platformImgIns.width + platformImgIns.width * index * 5, y: 550, image: platformImgIns, id: index + 1 });
        })
    }


    function init() {

        const platformImgIns = createImage(platformImg);

        player = new Player();
        if (isMobile) {

            platforms = resumeData.map((resume: any, index: number) => {
                return new Platform({ textBlock: resume.node.resume_steps.resumeyearsrange, x: index % 2 ? 100 : 10, y: 200 + 200 * index, image: platformImgIns, id: index + 1 });
            })

        } else {
            platforms = resumeData.map((resume: any, index: number) => {
                return new Platform({ textBlock: resume.node.resume_steps.resumeyearsrange, x: 400 + platformImgIns.width + platformImgIns.width * index * 5, y: 550, image: platformImgIns, id: index + 1 });
            })
        }
        scrollOffset = 0;
    }

    let currentKey = '';
    let isFalling = false;

    function animate() {
        let frame = requestAnimationFrame(animate);
        context.fillStyle = "rgba(255, 255, 255, 0.001)";
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillRect(0, 0, canvas.width, canvas.height);

        platforms.forEach((platform) => {
            platform.draw();
        })

        player.update();

        if (player.position.x < 500 && keys.right.pressed) {
            player.velocity.x = 5;
        } else if (keys.left.pressed && player.position.x > 500) {
            player.velocity.x = -5;
        } else {
            player.velocity.x = 0;
            if (keys.right.pressed) {
                scrollOffset += 5;
                platforms.forEach((platform) => {
                    platform.position.x -= 5;
                });

            } else if (keys.left.pressed) {
                scrollOffset -= 5;

                platforms.forEach((platform) => {
                    if (isMobile) { player.velocity.x = -5; }
                    else { platform.position.x += 5; }
                });

            }
        }

        if (isMobile) {
            if (player.position.y > 120) {
                player.velocity.y = 0;

                if (isFalling) {
                    platforms.forEach((platform) => {
                        platform.position.y -= 1;
                    });
                } else {
                    isFalling = true;
                }
            }

            if (keys.up.pressed) {
                platforms.forEach((platform) => {
                    platform.position.y += 15;
                });
            }
        }

        platforms.forEach((platform, index) => {
            if (!isMobile && frame % 20 === 0) {
                if (platform.position.x > 300 && platform.position.x < 740) {
                    if (CARDS && CARDS.length && CARDS[platform.id - 1]) {
                        CARDS.forEach((card) => {
                            card.style.visibility = 'hidden';
                        });
                        CARDS[platform.id - 1].style.visibility = 'visible';
                    }
                }

            }
            if (isMobile) {

                if (platform.position.y === player.position.y + player.height + player.velocity.y) {
                    if (CARDS && CARDS.length && CARDS[platform.id - 1]) {
                        CARDS.forEach((card) => {
                            card.style.visibility = 'hidden';
                        });
                        CARDS[platform.id - 1].style.visibility = 'visible';
                    }
                }
            }
            if (!isMobile) {
                if (player.position.y + player.height <=
                    platform.position.y &&
                    player.position.y + player.height +
                    player.velocity.y >=
                    platform.position.y &&
                    player.position.x + player.width >=
                    platform.position.x &&
                    player.position.x <=
                    platform.position.x + platform.width
                ) {
                    player.velocity.y = 0;
                }
            } else {
                if (player.position.y + player.height <=
                    platform.position.y &&
                    player.position.y + player.height +
                    player.velocity.y >=
                    platform.position.y &&
                    player.position.x + player.width >=
                    platform.position.x &&
                    player.position.x <=
                    platform.position.x + platform.width) {
                    isFalling = false;
                    player.velocity.y = 0;
                }
            }
        })


        if (keys.right.pressed && currentKey === 'right' && player.currentSprite !== player.sprites.run.right) {
            player.frames = 1;
            player.currentSprite = player.sprites.run.right;
            player.currentCropWidth = player.sprites.run.cropWidth;
            player.width = player.sprites.run.width;

        } else if (keys.left.pressed && currentKey === 'left' && player.currentSprite !== player.sprites.run.left) {
            player.currentSprite = player.sprites.run.left;
            player.currentCropWidth = player.sprites.run.cropWidth;
            player.width = player.sprites.run.width;
        } else if (!keys.left.pressed && currentKey === 'left' && player.currentSprite !== player.sprites.stand.left) {
            player.currentSprite = player.sprites.stand.left;
            player.currentCropWidth = player.sprites.stand.cropWidth;
            player.width = player.sprites.stand.width;
        } else if (!keys.right.pressed && currentKey === 'right' && player.currentSprite !== player.sprites.stand.right) {
            player.frames = 1;
            player.currentSprite = player.sprites.stand.right;
            player.currentCropWidth = player.sprites.stand.cropWidth;
            player.width = player.sprites.stand.width;

        }


        if (player.position.y > canvas.height) {
            init();
        }
    }

    animate();

    let isFocused = false;


    parentContainer.addEventListener('focus', () => {
        isFocused = (document.activeElement === parentContainer);
        if (isFocused) {
            document.body.style.overflow = 'hidden';
            canvas.classList.add(style.focused);
        };
    });

    parentContainer.addEventListener('blur', () => {
        isFocused = (document.activeElement === parentContainer);
        if (!isFocused) {
            document.body.style.overflow = 'auto';
            canvas.classList.remove(style.focused);
        };
    });

    if (!isMobile) {
        window.addEventListener('keydown', ({ keyCode }) => {

            switch (keyCode) {
                case 37:
                    keys.left.pressed = true;
                    currentKey = 'left';
                    break;

                case 40:
                    break;

                case 39:
                    keys.right.pressed = true;
                    currentKey = 'right';
                    break;

                case 38:
                    player.velocity.y -= isMobile ? 16 : 10;
                    break;
            }
        })


        parentContainer.addEventListener('keyup', ({ keyCode }) => {

            switch (keyCode) {
                case 37:
                    keys.left.pressed = false;

                    break;

                case 40:
                    break;

                case 39:
                    keys.right.pressed = false;
                    break;

                case 38:
                    break;
            }
        })
    } else {
        document.getElementById('mobileLeftBtn')?.addEventListener('touchstart', () => {
            keys.left.pressed = true;
            currentKey = 'left';
        });
        document.getElementById('mobileLeftBtn')?.addEventListener('touchend', () => {
            keys.left.pressed = false;
        });

        document.getElementById('mobileRightBtn')?.addEventListener('touchstart', () => {
            keys.right.pressed = true;
            currentKey = 'right';
        });
        document.getElementById('mobileRightBtn')?.addEventListener('touchend', () => {
            keys.right.pressed = false;
        });
        document.getElementById('mobileUptBtn')?.addEventListener('touchstart', () => {
            keys.up.pressed = true;
            player.velocity.y -= 10;
        });

        document.getElementById('mobileUptBtn')?.addEventListener('touchend', () => {
            keys.up.pressed = false;
        });

    }
}

function Resume({ resumeData }: any) {
    const [isfocused, setIsFocused] = React.useState<boolean>(false);
    const [dataOfParent, setDataOfParent] = React.useState<number>(0);
    const snap = useProxy(state);
    const [seeInfo, setSeeInfo] = React.useState<boolean>(false);

    const scrollToCenterFocusedElement = (el: HTMLElement) => {
        el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    useEffect(() => {
        const canvas: HTMLCanvasElement = document.getElementById('resumeGame') as HTMLCanvasElement;
        if (!snap.isMobile) {
            const parentContainer: HTMLDivElement = document.getElementById('timelineContainer') as HTMLDivElement;
            const cards: HTMLDivElement[] = Array.from(document.getElementsByClassName(style.card)) as HTMLDivElement[];
            canvasDraw(canvas, parentContainer, resumeData, null, undefined, cards);
        } else {
            const parentContainer: HTMLDivElement = document.getElementById('timelineContainerMobile') as HTMLDivElement;
            canvasDraw(canvas, parentContainer, resumeData, { x: 0, y: 0 }, snap.isMobile);
        }
    }, []);


    useEffect(() => {

        CARDS = Array.from(document.getElementsByClassName(style.card)) as HTMLDivElement[];

    }, [isfocused]);


    return (<section className={style.resumeContainer}>
        <h2 className={style.title}>My Resume</h2>
        {snap.isMobile
            ? <div
                onFocus={() => {
                    scrollToCenterFocusedElement(document.getElementById('timelineContainerMobile') as HTMLElement);
                    setIsFocused(true);
                }}
                onBlur={() => setIsFocused(false)}
                tabIndex={1} className={style.timelineContainer}
                id="timelineContainerMobile">
                {isfocused && resumeData.map((cardInfo: any) => {
                    return <motion.div
                        initial={{ opacity: 0, y: 200, x: '-50%' }}
                        whileInView={{ opacity: 1, y: -100, x: '-50%' }}
                        transition={{ delay: .5 }}
                        className={style.card}
                        style={{ display: seeInfo ? 'block' : 'none' }}>
                        <div>
                            <img src={cardInfo.node.image.node.sourceUrl} alt={cardInfo.node.title} />
                            <span className={style.innerTitle}>{cardInfo.node.title}</span>
                        </div>
                        <h3>{cardInfo.node.resume_steps.resumeyearsrange}</h3>
                        <span>{cardInfo.node.resume_steps.resumecontent}</span>

                    </motion.div>
                })}

                {!isfocused && <div className={style.instructions}>
                    <button className={style.clickPlay}>Click to Play</button>
                    <p>use the arrows to play</p>
                    <img src={arrow_keys} alt="arrow keys" />
                </div>}

                <canvas className={style.canvas + ' ' + (isfocused ? style.focused : null)} id="resumeGame"></canvas>
                {<div style={{ display: 'flex', flexWrap: 'wrap', position: 'relative', zIndex: 20, top: -20 }}>
                    <button className={style.mobilePlayBtn + ' ' + (isfocused && style.mobilePlayBtnFocused)} id="mobileLeftBtn">Left</button>
                    <button className={style.mobilePlayBtn + ' ' + (isfocused && style.mobilePlayBtnFocused)} id="mobileUptBtn">Up</button>
                    <button className={style.mobilePlayBtn + ' ' + (isfocused && style.mobilePlayBtnFocused)} id="mobileRightBtn">Right</button>
                    <button onClick={() => setSeeInfo(!seeInfo)} className={style.mobilePlayBtnFull + ' ' + (isfocused && style.mobilePlayBtnFocused)} >{seeInfo ? 'Close' : 'See Details'}</button>
                </div>}
            </div>
            : <div
                onFocus={() => {
                    scrollToCenterFocusedElement(document.getElementById('timelineContainer') as HTMLElement);
                    setIsFocused(true)
                }}
                onBlur={() => setIsFocused(false)}
                tabIndex={1} className={style.timelineContainer} id="timelineContainer">
                {!isfocused && <div className={style.instructions}>
                    <button className={style.clickPlay}>Click to Play</button>
                    <p>use the arrows to play</p>
                    <img src={arrow_keys} alt="arrow keys" />
                </div>}
                {isfocused && resumeData.map((cardInfo: any) => {
                    return <motion.div initial={{ opacity: 0, y: 200, x: '-50%' }} whileInView={{ opacity: 1, y: -100, x: '-50%' }} transition={{ delay: .5 }} className={style.card}>
                        <div>
                            <img src={cardInfo.node.image.node.sourceUrl} alt={cardInfo.node.title} />
                            <span className={style.innerTitle}>{cardInfo.node.title}</span>
                        </div>
                        <h3>{cardInfo.node.resume_steps.resumeyearsrange}</h3>
                        <span>{cardInfo.node.resume_steps.resumecontent}</span>

                    </motion.div>
                })}
                {isfocused && <button className={style.closeGame}></button>}
                <canvas className={style.canvas} id="resumeGame"></canvas>
            </div>}
        <div className={styleStars.galaxy}>
            <div className={styleStars.starsSmall}></div>
            <div className={styleStars.starsMedium}></div>
            <div className={styleStars.starsLarge}></div>
            <div className={styleStars.galaxyShadow}></div>
        </div>
    </section>);
}

export default Resume;