import { useEffect, useRef, useState } from "react";

import { useProxy } from "valtio/utils";
import { state } from "../../store";

import useScrollBarCustom from "../../hooks/useScrollBarCustom";
import { IPoject } from "../../models";
import style from './ProjectsGallery.module.scss';
import gStyle from '../../style.module.scss';

function ProjectsGallery({ projects }: { projects: IPoject[] }) {




    const myRef = useRef<HTMLElement>(null);
    const [isInContainer, setIsInContainer] = useState(false);
    const horizontalScollProjects = useScrollBarCustom(myRef.current);
    const snap = useProxy(state);

    useEffect(() => {
        if (!snap.isMobile) horizontalScollProjects();
        setIsInContainer(true);
    }, [isInContainer]);


    const handleViewProject = (link: string): void => {
        window.open(link, '_blank');
    }

    return (
        <section ref={myRef} className={style.projectsContainer + ' wrapper'}>
            {projects.map(({ node: project }: IPoject) =>
                <div style={{ backgroundImage: `url( ${snap.isMobile ? project.projects_meta?.imgformobile?.sourceUrl : project.image.node.sourceUrl} )` }} className={style.project} key={project.id}>
                    <div className={style.projectContent}>
                        <h2>{project.title}</h2>
                        <p>{project.projects_meta.excerpt}</p>
                        <button className={gStyle.primaryBtn + ' ' + style.primaryBtnProject} onClick={() => handleViewProject(project.projects_meta.link)}>View Project

                            <svg id="Btn_arrow_icon" className={style.svgBtnArrow} width="17" height="17" viewBox="0 0 17 17">
                                <g id="Group_2" data-name="Group 2" transform="translate(662 -917)">
                                    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(-662 917)" fill="none" stroke="#fff" strokeWidth="2">
                                        <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
                                        <circle cx="8.5" cy="8.5" r="7.5" fill="none" />
                                    </g>
                                    <g id="Group_1" data-name="Group 1">
                                        <path id="Path_1" data-name="Path 1" d="M-682.662,928.25h4.575" transform="translate(26.287 -2.628)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
                                        <path id="Path_2" data-name="Path 2" d="M-678.088,925.5l2.628,2.628-2.628,2.628" transform="translate(24 -2.628)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </g>
                                </g>
                            </svg>

                        </button>
                    </div>
                </div>
            )}
        </section>);
}

export default ProjectsGallery;