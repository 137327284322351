import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
function useScrollBarCustom(container: HTMLElement | null) {
    const horizontalScollProjects = () => {
        if (!container) return;
        gsap.to(container, {
            x: () => -container.offsetWidth * 3,
            scrollTrigger: {
                trigger: ".wrapper",
                start: "top top",
                end: () => "+=" + container.offsetWidth * 4,
                pin: ".wrapper",
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
            },
        });
    };

    return horizontalScollProjects;
}

export default useScrollBarCustom;
