import style from './Hero.module.scss';
import generalStyle from '../../style.module.scss';

function Hero({ data }: any) {


    return (<div className={generalStyle.widthContainer}>
        <h1 className={style.mainTitle}>
            <img src={data.page.image.node.sourceUrl} alt="hero" className={style.heroImg} />
            {data.page.title}</h1>

        <p className={style.info}>{data.page.mainpage.info}</p>
    </div>);
}

export default Hero;