import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);



function ScrollNotation() {

    const styleWrapper: any = {
        position: 'absolute', zIndex: 100, bottom: 60, border: '2px solid #e3e3e3', borderRadius: '50px', width: 20, height: 40,
        left: '50%', trasform: 'rotateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center'
    }

    const styleInner: any = {
        position: 'absolute', bottom: 3, backgroundColor: '#e3e3e3', borderRadius: '50px', width: 6, height: 6
    }

    const elementWrap: any = useRef();
    const elementInner: any = useRef();

    useEffect(() => {

        gsap.timeline().fromTo(elementInner.current, { y: 0 }, { y: -22, repeat: -1, yoyo: true, duration: 1 })
        gsap.fromTo(elementWrap.current, { opacity: 1 }, {
            opacity: 0,
            duration: .1,
            scrollTrigger: {
                trigger: elementWrap.current,
                start: 'top center',
                end: 'bottom top',
                scrub: true,
            },
        });

    }, []);

    return (<div ref={elementWrap} style={styleWrapper}>
        <div ref={elementInner} style={styleInner}></div>
    </div>);
}

export default ScrollNotation;